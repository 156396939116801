var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Paginationview" },
    [
      _c("el-pagination", {
        attrs: {
          type: "primary",
          background: "",
          "current-page": _vm.currentPagecnt,
          "page-sizes": [10, 20, 50, 100, 200, 500, 1000],
          "page-size": _vm.pageSize,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.totalcnt
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }