import { BASE } from './index';
import axios from './http';

const setUp = {
    //部门 获取菜单
    menutree: params => axios.get(`${BASE.PRO}/api/bms/manager/menu/tree`, { params }),
    // 角色获取菜单
    rolemenu: params => axios.get(`${BASE.PRO}/api/bms/manager/role/menu`, { params }),
    //部门全部接口
    //部门添加
    departmentsave: params => axios.post(`${BASE.PRO}/api/bms/manager/department/save`, params),
    //禁用启用
    departmentstatusupdate: params => axios.post(`${BASE.PRO}/api/bms/manager/department/status/update`, params),
    //业务配置详情
    departmentinfo: params => axios.get(`${BASE.PRO}/api/bms/manager/department/info`, { params }),
    //部门下拉框
    departmentselected: params => axios.get(`${BASE.PRO}/api/bms/manager/department/select`, { params }),
    //业务配置更改
    departmentmenuupdate: params => axios.post(`${BASE.PRO}/api/bms/manager/department/menu/update`, params),
    //角色接口
    //角色添加编辑
    rolesave: params => axios.post(`${BASE.PRO}/api/bms/manager/role/save`, params),
    //禁用启用
    rolestatusupdate: params => axios.post(`${BASE.PRO}/api/bms/manager/role/status/update`, params),
    //业务配置详情
    roleinfo: params => axios.get(`${BASE.PRO}/api/bms/manager/role/info`, { params }),
    //业务配置更改
    rolemenuupdate: params => axios.post(`${BASE.PRO}/api/bms/manager/role/menu/update`, params),
    //用户管理
    // 禁用启用
    adminstatusupdate: params => axios.post(`${BASE.PRO}/api/bms/manager/admin/status/update`, params),
    // 新增用户管理
    adminsave: params => axios.post(`${BASE.PRO}/api/bms/manager/admin/save`, params),
    // 获取所有角色
    roleselect: params => axios.get(`${BASE.PRO}/api/bms/manager/role/select`, params),

    //供货商
    // 启用禁用
    supplierstatusupdate: params => axios.post(`${BASE.PRO}/api/bms/supplier/status/update`, params),
    //清关公司
    // 启用禁用
    clearancestatusupdate: params => axios.post(`${BASE.PRO}/api/bms/clearance/status/update `, params),
    // 库房
    // 启用禁用
    storeroomstatusupdate: params => axios.post(`${BASE.PRO}/api/bms/storeroom/status/update`, params),
    // 添加库房接口
    storeroomsave: params => axios.post(`${BASE.PRO}/api/bms/storeroom/save`, params),
    // 库房属性下拉框数据
    storeroomgettype: params => axios.get(`${BASE.PRO}/api/bms/storeroom/get/type`, { params }),


    //品类
    //品类标准库启用禁用
    goodsstateupdate: params => axios.post(`${BASE.PRO}/api/bms/goods/state/update`, params),
    //品类标准库
    goodsgettype: params => axios.get(`${BASE.PRO}/api/bms/goods/get/type`, { params }),
    //品类一级分类
    firstcategory: params => axios.get(`${BASE.PRO}/api/bms/goods/first/category`, { params }),
    //品类二级分类
    secondcategory: params => axios.get(`${BASE.PRO}/api/bms/goods/second/category`, { params }),
    //品类标准库
    goodssave: params => axios.post(`${BASE.PRO}/api/bms/goods/save`, params),
    //价格跟踪
    tagstateupdate: params => axios.post(`${BASE.PRO}/api/bms/goods/tag/state/update`, params),
    // 编辑商品价格
    tagpricesave: params => axios.post(`${BASE.PRO}/api/bms/goods/tag/price/save`, params),
    // 编辑商品价格new
    tagpricesavenew: params => axios.post(`${BASE.PRO}/api/bms/goods/price/save`, params),
    // 价格跟踪编辑
    goodstagpricesave: params => axios.post(`${BASE.PRO}/api/bms/goods/tag/price/save`, params),


    //主体信息
    // 启用禁用
    bankstatusupdate: params => axios.post(`${BASE.PRO}/api/bms/enterprise/bank/status/update`, params),
    // 修改主体信息资费用
    enterpriseinfoupdate: params => axios.post(`${BASE.PRO}/api/bms/enterprise/info/update`, params),
    //获取主体信息内容
    enterpriseinfo: params => axios.get(`${BASE.PRO}/api/bms/enterprise/info`, { params }),

    //业务配置页面
    businesscategoryget: params => axios.get(`${BASE.PRO}/api/bms/business/category/get`, { params }),
    // 添加业务模型配置
    businesscategoryadd: params => axios.post(`${BASE.PRO}/api/bms/business/category/add`, params),

//业务配置工单添加
wotemplatesave: params => axios.post(`${BASE.PRO}/api/wo/template/save`, params),




};

export default setUp;
