import { BASE } from './index';
import axios from './http';

const settlement = {
     //赎货结算列表
     settlementlist: params => axios.post(`${BASE.PRO}/api/wo/put/list`,  params ),
     //获取计算收费信息
     expenseinfo: params => axios.post(`${BASE.PRO}/api/wo/settlement/expense/info`,  params ),
     //保存编辑
     settlementsave: params => axios.post(`${BASE.PRO}/api/wo/settlement/save`,  params ),
     //发送结算单
     settlementsend: params => axios.post(`${BASE.PRO}/api/wo/settlement/send`,  params ),
     //确认结算单
     settlementconfirm: params => axios.post(`${BASE.PRO}/api/wo/settlement/confirm`,  params ),
     //运营作废
     settlementvoid: params => axios.post(`${BASE.PRO}/api/wo/settlement/void`,  params ),
   
   
};

export default settlement;