import { BASE } from './index';
import axios from './http';

const download = {
    //结算下载
    settlementdownload: params => axios.post(`${BASE.PRO}/api/wo/settlement/download`,  params ),
    //入库下载
    woputdownload: params => axios.post(`${BASE.PRO}/api/wo/put/download`,  params ),
    //出库下载
    wooutdownload: params => axios.post(`${BASE.PRO}/api/wo/out/download`,  params ),
   
   
};

export default download;
