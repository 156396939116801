import { BASE } from './index';
import axios from './http';

const INDEX = {
    //登录接口
    login: params => axios.post(`${BASE.PRO}/api/bms/manager/login`,  params ),
    //退出登录
    loginout: params => axios.post(`${BASE.PRO}/api/bms/manager/logout`,  params )
};

export default INDEX;
