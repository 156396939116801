import { BASE } from './index';
import axios from './http';

const ci = {
    //审单列表列表
    orderreviewlist: params => axios.get(`${BASE.PRO}/api/bs/order/review/list`,  {params} ),
    //审单列表列表
    cherryorderreviewlist: params => axios.get(`${BASE.PRO}/api/bs/order/cherry/review/list`,  {params} ),
    //审单详情
    orderreviewinfo: params => axios.get(`${BASE.PRO}/api/bs/order/review/info`,  {params} ),
    //审单详情
    cherryorderreviewinfo: params => axios.get(`${BASE.PRO}/api/bs/order/cherry/review/info`,  {params} ),
    //依据PI获取对应的CI数据
    purchasecabinetCode: params => axios.get(`${BASE.PRO}/api/bs/order/purchase/cabinetCode`,  {params} ),
    //审单列表列表-作废
    orderreviewcancel: params => axios.post(`${BASE.PRO}/api/bs/order/review/cancel`,  params ),
    //审单列表列表-作废
    cherryorderreviewcancel: params => axios.post(`${BASE.PRO}/api/bs/order/cherry/review/cancel`,  params ),
    //审单列表列表-保存
    orderreviewsave: params => axios.post(`${BASE.PRO}/api/bs/order/review/save`,  params ),
    //审单列表列表-保存
    cherryorderreviewsave: params => axios.post(`${BASE.PRO}/api/bs/order/cherry/review/save`,  params ),
    //审单列表列表-确认
    orderreviewconfirm: params => axios.post(`${BASE.PRO}/api/bs/order/review/confirm`,  params ),
    //审单列表列表-确认
    cherryorderreviewconfirm: params => axios.post(`${BASE.PRO}/api/bs/order/cherry/review/confirm`,  params ),
    //获取审批单保证金参数
    cabinetCodereminder: params => axios.post(`${BASE.PRO}/api/bs/order/purchase/cabinetCode/reminder`,  params ),
    //ci发起审批详情
    finalPaymentinfo: params => axios.get(`${BASE.PRO}/api/bs/approval/finalPayment/info`,  {params} ),

   //ci发起审批
   finalPaymentapproval: params => axios.post(`${BASE.PRO}/api/bs/approval/finalPayment/approval`,  params ),
   //ci发起审批-撤销
   finalPaymentrevoke: params => axios.post(`${BASE.PRO}/api/bs/approval/finalPayment/revoke`,  params ),
   //ci获取pi单号查询数据
   cherrypurchaseselect: params => axios.post(`${BASE.PRO}/api/bs/order/cherry/purchase/select`,  params ),
   //ci获取pi列表
   cherrypurchasecabinetCode: params => axios.post(`${BASE.PRO}/api/bs/order/cherry/purchase/cabinetCode`,  params ),
   //车厘子-ci获取pi单号详情
   cherrygetSaveInfo: params => axios.post(`${BASE.PRO}/api/bs/order/cherry/balance/getSaveInfo`,  params ),
   //车厘子-ci获取pi单号查询数据
   cherrypurchacherry: params => axios.post(`${BASE.PRO}/api/bs/order/cherry/balance/effective`,  params ),
   //车厘子-尾款保存
   cherrybalancesave: params => axios.post(`${BASE.PRO}/api/bs/order/cherry/balance/save`,  params ),
   //车厘子-尾款详情
   cherryreviewinfo: params => axios.post(`${BASE.PRO}/api/bs/order/cherry/review/info`,  params ),
   //车厘子-确定
   cherrybalanceconfirm: params => axios.post(`${BASE.PRO}/api/bs/order/cherry/balance/confirm`,  params ),

   //ci发起审批详情
   finalPaymentwoInfo: params => axios.get(`${BASE.PRO}/api/bs/approval/finalPayment/woInfo`,  {params} ),

   
};

export default ci;
