import { BASE } from './index';
import axios from './http';

const oa = {
     //审批模板列表
     oaflowlist: params => axios.get(`${BASE.PRO}/api/oa/flow/list`,  {params} ),
     //审批审批用户列表
     oaflowuserlist: params => axios.get(`${BASE.PRO}/api/oa/flow/user/list`,  {params} ),
     //流程详情
     oaflowdetail: params => axios.get(`${BASE.PRO}/api/oa/flow/detail`,  {params} ),
     //流程保存
     oaflowsave: params => axios.post(`${BASE.PRO}/api/oa/flow/save`,  params ),
     //流程发布
     oaflowpublish: params => axios.post(`${BASE.PRO}/api/oa/flow/publish`,  params ),
   
};

export default oa;
