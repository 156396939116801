<template>
  <div class="searchQuery">
    <div class="searchtop">
      <el-button
        type="primary"
        icon="el-icon-refresh"
        class="bjsearch"
        @click="Refresh(1)"
      />
      <el-button
        icon="el-icon-s-fold"
        plain
        @click="hideshow = !hideshow"
        v-if="formList.length > 0"
        >{{ hideshow ? "隐藏" : "展开" }}</el-button
      >
      <el-button
        :icon="item.icon"
        plain
        v-for="(item, index) in btnlist"
        :key="index"
        :loading="item.loading ? item.loading : false"
        :disabled="!item.checked"
        @click="events(item)"
        >{{ item.name }}</el-button
      >
    </div>
    <div class="searchbottom" v-show="hideshow">
      <div class=" marginr8" v-for="(item, index) in formList" :key="index">
        <el-input
          v-model="searchForm[item.label]"
          :placeholder="item.placeholder"
          class="inputs"
          clearable
          v-if="item.type == 'input'"
        />
        <el-date-picker
          v-if="item.type == 'datapicker'"
          v-model="searchForm[item.label]"
          type="date"
          class="inputs"
          clearable
          :placeholder="item.placeholder"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
        <el-date-picker
          v-if="item.type == 'datetimerange'"
          v-model="searchForm[item.label]"
          type="datetimerange"
          clearable
          :start-placeholder="`${item.placeholder}开始日期`"
          :end-placeholder="`${item.placeholder}结束日期`"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
        <el-date-picker
          v-if="item.type == 'daterange'"
          v-model="searchForm[item.label]"
          type="daterange"
          clearable
          :start-placeholder="`${item.placeholder}开始日期`"
          :end-placeholder="`${item.placeholder}结束日期`"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
        <el-select
          v-if="item.type == 'select'"
          v-model="searchForm[item.label]"
          filterable
          clearable
          class="inputs"
          :placeholder="item.placeholder"
        >
          <el-option
            v-for="e in item.options"
            :key="e[item.value]"
            :label="e[item.labelopt]"
            :value="e[item.value]"
          />
        </el-select>
      </div>

      <div class="marginr8">
        <el-button type="primary" icon="el-icon-search" @click="search">
          查询
        </el-button>
        <el-button type="warning" icon="el-icon-refresh" @click="empty">
          重置
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    //筛选列表项
    formList: {
      type: Array,
      default: function() {
        //这里默认空字符串
        return [];
      }
    },
    //     {type:'',//adddialog添加弹框，addpush跳转页面，export//导出...如有新增自行往后添加
    //   name:''//按钮展示名称,
    // icon:''//按年图标}
    btnlist: {
      type: Array,
      default: function() {
        //这里默认空字符串
        return [];
      }
    },
    nonelist: {
      type: Array,
      default: function() {
        //这里默认空字符串
        return [];
      }
    },
    from: {
      type: Object,
      default: function() {
        //这里默认空字符串
        return {};
      }
    }
  },
  data() {
    return {
      //展开收起
      hideshow: false,
      //查询条件
      searchForm: {}
    };
  },
  computed: {},
  watch: {
    from: {
      handler: function(newValue, oldValue) {
        this.$nextTick(() => {
          this.searchForm = {
            ...newValue
          };
        });
      },
      deep: true
    }
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.searchForm = {
        ...this.from
      };
    });
    // this.hqform()
    // console.log(this.formList,'2222222222222222222')
  },
  methods: {
    //
    hqform() {
      this.formList.forEach(el => {
        this.searchForm[el.label] = "";
      });
    },
    //点击按钮触发事件
    events(e) {
      this.$emit(e.type, true);
    },

    //点击查询
    search() {
      console.log(this.searchForm);
      this.$emit("search", this.searchForm);
    },
    //清空筛选条件
    empty() {
      for (var key in this.searchForm) {
        if (!this.nonelist.includes(key)) {
          if (Array.isArray(this.searchForm[key])) {
            // 如果属性为数组
            this.searchForm[key] = []; // 直接置空数组
          } else if (
            typeof this.searchForm[key] === "object" &&
            !Array.isArray(this.searchForm[key]) &&
            this.searchForm[key] !== null
          ) {
            // 如果属性为非空对象（且非数组）
            // clearObject(this.searchForm[key]); // 递归调用函数自身，清空该对象内的所有属性
            this.searchForm[key] = {};
          } else {
            this.searchForm[key] = "";
          }
        }
      }
    },
    //刷新按钮
    Refresh(e) {
      this.empty();

      this.$emit("search", this.searchForm, e);
    }
  }
};
</script>

<style lang="scss">
.searchQuery {
  .searchtop {
  }
  .searchbottom {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    .inputs {
      width: 207px;
    }
    .marginr8 {
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
}
</style>
