import { BASE } from './index';
import axios from './http';

const qg = {
    //清报关列表
    orderclearancelist: params => axios.get(`${BASE.PRO}/api/bs/order/clearance/list`,  {params} ),
    //清报关列表
    cherryorderclearancelist: params => axios.post(`${BASE.PRO}/api/bs/order/cherry/clearance/list`,  params ),
    //清报关详情
    orderclearanceinfo: params => axios.get(`${BASE.PRO}/api/bs/order/clearance/info`,  {params} ),
    //清报关详情
    cherryclearanceinfo: params => axios.post(`${BASE.PRO}/api/bs/order/cherry/clearance/info`,  params ),
    //清报关列表-作废
    orderclearancecancel: params => axios.post(`${BASE.PRO}/api/bs/order/clearance/cancel`,  params ),
    //清报关列表-添加
    orderclearancesave: params => axios.post(`${BASE.PRO}/api/bs/order/clearance/save`,  params ),
    //清报关列表-保存
    cherryclearancesave: params => axios.post(`${BASE.PRO}/api/bs/order/cherry/clearance/save`,  params ),
    //清报关列表-清报关完成按钮
    cherryclearancecomplete: params => axios.post(`${BASE.PRO}/api/bs/order/cherry/clearance/complete`,  params ),
    //清报关列表-清报关完成按钮
    orderclearancecomplete: params => axios.post(`${BASE.PRO}/api/bs/order/clearance/complete`,  params ),
    //清报关列表-能否清关验证
    orderclearancecheck: params => axios.post(`${BASE.PRO}/api/bs/order/clearance/check`,  params ),
    //清报关获取审批详情
    approvaltaxinfo: params => axios.post(`${BASE.PRO}/api/bs/approval/tax/info`,  params ),
    //清报关发起审批
    approvaltaxinitiate: params => axios.post(`${BASE.PRO}/api/bs/approval/tax/initiate`,  params ),
    //清报关重新发起
    approvaltaxresubmit: params => axios.post(`${BASE.PRO}/api/bs/approval/tax/resubmit`,  params ),
    //清报关撤销
    approvaltaxrevoket: params => axios.post(`${BASE.PRO}/api/bs/approval/tax/revoke`,  params ),
    //清报关作废
    approvaltaxcancel: params => axios.post(`${BASE.PRO}/api/bs/approval/tax/cancel`,  params ),
    //清关列表获取详情
    approvaltaxgetInfoByNum: params => axios.post(`${BASE.PRO}/api/bs/approval/tax/getInfoByNum`,  params ),
    //清关列表检测数据有效
    approvaltaxcheckValid: params => axios.post(`${BASE.PRO}/api/bs/approval/tax/checkValid`,  params ),
    //清关上传检疫证
    clearanceupload: params => axios.post(`${BASE.PRO}/api/bs/order/cherry/clearance/upload`,  params ),
   
};

export default qg;
