import { BASE } from './index';
import axios from './http';

const buyer = {
    //客商添加、编辑
    buyersave: params => axios.post(`${BASE.PRO}/api/crm/buyer/save`, params),
    //准入条款保存
    informationsave: params => axios.post(`${BASE.PRO}/api/crm/buyer/credit/information/save`, params),
    //客商详情
    buyerinfo: params => axios.get(`${BASE.PRO}/api/crm/buyer/info`, { params }),
    //客商列表
    buyerlist: params => axios.get(`${BASE.PRO}/api/crm/buyer/list`, { params }),
    //准入条款详情
    informationinfo: params => axios.get(`${BASE.PRO}/api/crm/buyer/credit/information/info`, { params }),
    //终止准入条款详情
    informationterminate: params => axios.post(`${BASE.PRO}/api/crm/buyer/credit/information/terminate`, params),
    //合作模式详情
    cooperationmodeinfo: params => axios.get(`${BASE.PRO}/api/crm/buyer/cooperation/mode/info`, { params }),
    //融资条款列表
    financingtermslist: params => axios.get(`${BASE.PRO}/api/crm/buyer/financing/terms/list`, { params }),
    //添加合作模式
    cooperationmodeadd: params => axios.post(`${BASE.PRO}/api/crm/buyer/cooperation/mode/add`, params),
    //删除合作模式
    cooperationmodedelete: params => axios.post(`${BASE.PRO}/api/crm/buyer/cooperation/mode/delete`, params),
    //添加合作模式详情信息
    cooperationmodeupdate: params => axios.post(`${BASE.PRO}/api/crm/buyer/cooperation/mode/update`, params),
    //添加准入条款第二步骤下一
    cooperationmodebatch: params => axios.post(`${BASE.PRO}/api/crm/buyer/cooperation/mode/batch/save`, params),
    //融资条款详情添加编辑
    financingtermssave: params => axios.post(`${BASE.PRO}/api/crm/buyer/financing/terms/save`, params),
    //融资条款详情第三步
    creditinformationcomplete: params => axios.post(`${BASE.PRO}/api/crm/buyer/credit/information/complete`, params),
    //融资条款详情
    financingtermsinfo: params => axios.get(`${BASE.PRO}/api/crm/buyer/financing/terms/info`, { params }),
    //准入条款详情
    creditinformationinfo: params => axios.get(`${BASE.PRO}/api/crm/buyer/credit/information/info`, { params }),
    //准入条款获取合作信息
    cooperationmodelist: params => axios.get(`${BASE.PRO}/api/crm/buyer/cooperation/mode/list`, { params }),
    //准入条款获取当前有哪些合作模式
    cooperationmodeappend: params => axios.get(`${BASE.PRO}/api/crm/buyer/cooperation/mode/append`, { params }),
    //准入条款猎豹
    informationlist: params => axios.get(`${BASE.PRO}/api/crm/buyer/credit/information/list`, { params }),
    //审批列表客商管理
    approvallist: params => axios.get(`${BASE.PRO}/api/crm/buyer/approval/list`, { params }),
    //审批管理撤销审批
    approvalrevoke: params => axios.post(`${BASE.PRO}/api/crm/buyer/approval/revoke`, params),
    //准入条款发审批
    informationapproval: params => axios.post(`${BASE.PRO}/api/crm/buyer/credit/information/approval`, params),
    //准入条款重新发审批
    informationreissue: params => axios.post(`${BASE.PRO}/api/crm/buyer/approval/reissue`, params),
    //准入条款审批详情
    creditapprovalinfo: params => axios.post(`${BASE.PRO}/api/crm/buyer/credit/approval/info`, params),
    //定向协议审批详情
    protocolapprovalinfo: params => axios.get(`${BASE.PRO}/api/crm/buyer/directional/protocol/approval/info`, {params}),
    //审批详情
    approvalinfo: params => axios.post(`${BASE.PRO}/api/crm/buyer/approval/info`, params),
//授信额度变更审批
fundingPartyapproval: params => axios.post(`${BASE.PRO}/api/crm/buyer/credit/information/fundingParty/add/approval`, params),

    

    
};

export default buyer;
