import { BASE } from './index';
import axios from './http';

const Approval = {
    //审批列表
    approvallist: params => axios.post(`${BASE.PRO}/api/approval/list`,  params ),
    //审批详情
    approvalinfo: params => axios.post(`${BASE.PRO}/api/approval/info`,  params ),
    //内贸代采发起审批详情
    approvalbaseinfo: params => axios.post(`${BASE.PRO}/api/bs/dt/pa/order/approval/purchase/base_info`,  params ),
    //内贸代采发起审批详情
    approvalfirstbaseinfo: params => axios.post(`${BASE.PRO}/api/wo/dt/pa/rpft/approval/base_info`,  params ),
    //内贸代采保存审批
    approvalapprovesave: params => axios.post(`${BASE.PRO}/api/bs/dt/pa/order/approval/purchase/save`,  params ),
    //内贸代采保存审批
    approvalapprovefirstsave: params => axios.post(`${BASE.PRO}/api/wo/dt/pa/rpft/approval/save`,  params ),
    //发起审批
    approvalapproval: params => axios.post(`${BASE.PRO}/api/approval/approval`,  params ),
    //撤销审批
    approvalrevoke: params => axios.post(`${BASE.PRO}/api/approval/revoke`,  params ),
};

export default Approval;
