<template>
  <div class="renovationtable">
    <el-table
      v-loading="vloading"
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      height="100%"
      @row-click="btn"
      :border="true"
      @header-dragend="doLayout"
      :header-cell-style="$common.tableHeaderColor"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" align="center" width="55" >
      </el-table-column>
      <el-table-column
        type="index"
        label="序号"
        width="80"
        align="center"
        
      >
      </el-table-column>
      <el-table-column
        :prop="item.prop"
        :label="item.label"
        :width="item.width?item.width:''"
        :show-overflow-tooltip="true"
        v-for="(item, index) in tableDatacolumn"
        :key="index"
        align="center"
      >
        <template slot-scope="scope">
          <div
            v-if="item.type == 'path'"
            class="path ovflowhinden"
            @click="tabpath(scope.row)"
          >
            {{ scope.row[item.prop] }}
          </div>
          <div v-else-if="item.type == 'arr'" class="ovflowhinden">
            <!-- {{ scope.row[item.prop] }} -->
            <div v-for="item1 in scope.row[item.prop]" :key="item1.id">
              {{ item1.name }}
            </div>
          </div>
          <div v-else-if="item.type == 'input'" class="ovflowhinden">
            <el-input
              v-model="scope.row[item.prop]"
              placeholder="请输入内容"
              @blur="inputBlur(scope.row)"
            ></el-input>
          </div>
          <div v-else class="ovflowhinden">{{ scope.row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "renovationtable",
  components: {},
  props: {
    // 表格展示
    quality: {
      type: Object,
      default: {
        tableDatacolumn: [],
        css: {},
      },
    },
    // 列表数据
    listdata: {
      type: Array,
      default: function () {
        //这里默认return一个数组，即可解决上述的报错问题
        return [];
      },
    },
    // 加载
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableData: [],
      tableDatacolumn: [],
      flag: false,
    };
  },
  computed: {
    vloading() {
      return this.loading;
    },
  },
  watch: {
    quality: {
      handler: function (newValue, oldValue) {
        console.log("quality参数", newValue);
        this.tableDatacolumn = newValue.tableDatacolumn;
      },
      deep: true,
    },
    listdata: {
      handler: function (newValue, oldValue) {
        this.tableData = newValue;
      },
      deep: true,
    },
  },
  created() {
    this.tableDatacolumn = this.quality.tableDatacolumn;
    this.tableData = this.listdata;
  },
  mounted() {},
  methods: {
    doLayout() {
      this.$nextTick(() => {
        this.$refs.multipleTable.doLayout();
      });
    },

    tabpath(e) {
      this.$emit("pathtable", e);
    },
    btn(row, col, event) {
      // console.log(row,'111111111111')
      row.flag = !row.flag;
      this.$refs.multipleTable.toggleRowSelection(row, row.flag);
    },
    numberlistname(e, i) {
      e = e.map((el) => el.name);
      return e.join("</br>");
      // let arr = [];
      //   e.detail.forEach((el) => {
      //     arr.push(el.actualContainerNo);
      //   });
      //   return arr.join("</br>")==''?'—':arr.join("</br>");
    },
    inputBlur(val) {
      this.$emit("inputBlur", val);
    },
    handleSelectionChange(val) {
      console.log(val, "选中的数据");
      this.$emit("tablevalue", val);
      // this.multipleSelection = val;
    },
  },
};
</script>

<style lang="scss">
.renovationtable {
  width: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  background-color: #fff;
  overflow-y: hidden;
  .el-table__fixed {
    height: 100% !important;
  }
  .path {
    color: $theme-color;
    cursor: pointer;
  }
  .ovflowhinden{
    width: 100%;
    white-space: nowrap; /* 防止文本换行 */
    overflow: hidden; /* 隐藏超出容器的内容 */
    text-overflow: ellipsis; /* 当文本溢出时显示省略号 */
  }
}
</style>
