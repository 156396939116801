import { BASE } from "@/api/index";
import axios from "@/api/http";

const BASE_URL = BASE.PRO;

// 工单-保证金-收预付款保证金获取基础信息
export function postDepositBaseInfo(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/security/deposit/base/info`, params);
}

// 工单-保证金-添加、编辑收预付款保证金
export function postDepositBaseSave(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/security/deposit/save`, params);
}

// 工单-保证金-收预付款保证金详情
export function postDepositInfo(params = {}) {
  return axios.get(`${BASE_URL}/api/wo/security/deposit/info`, {params});
}

// 工单-工单列表
export function getBsWoList(params = {}) {
  return axios.get(`${BASE_URL}/api/wo/list`, {params});
}

// 工单-保证金-尾款补保证金基础信息
export function postRepairBaseInfo(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/repair/security/deposit/base/info`, params);
}

// 工单-保证金-收尾款补保证金详情
export function getRepairDepositInfo(params = {}) {
  return axios.get(`${BASE_URL}/api/wo/repair/security/deposit/info`, {params});
}

// 工单-保证金-添加、编辑收尾款补保证金基础信息
export function postRepairDepositSave(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/repair/security/deposit/save`, params);
}

// 工单-银行分组列表
export function getBankGroupList(params = {}) {
  return axios.get(`${BASE_URL}/api/bms/enterprise/bank/group/list`, {params});
}

// // 工单-银行分组获取账号
// export function getBankGroupAccountList(params = {}) {
//   return axios.get(`${BASE_URL}/api/bms/enterprise/bank/account/group/list`, {params});
// }

// 工单-银行分组获取账号
export function getBankGroupAccountList(params = {}) {
  return axios.get(`${BASE_URL}/api/bms/enterprise/bank/account/get`, {params});
}

// 工单-保证金-作废收预付款保证金
export function postDepositVoid(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/security/deposit/void`, params);
}

// 工单-保证金-作废收尾款补保证金
export function postRepairVoid(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/repair/security/deposit/void`, params);
}

// 工单-补保证金-发送财务
export function postRepairFinance(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/repair/security/deposit/dispatch/finance`, params);
}

// 工单-保证金-上传凭证
export function postPaymentSave(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/security/deposit/payment/voucher/save`, params);
}

// 工单-保证金-上传凭证
export function postRepairSave(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/repair/security/deposit/payment/voucher/save`, params);
}

// 工单-保证金-发送财务
export function postSecurityFinance(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/security/deposit/dispatch/finance`, params);
}

// 工单-下载预付文件
export function postCommonExport(params = {}) {
  return axios.post(`${BASE_URL}/api/common/export`, params);
}

// 工单-预付货款-上传预付文件
export function postAdvanceAttach(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/prepaid/advance/attach`, params);
}

// 工单-尾款-上传预付文件
export function postFinalAttachUpload(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/final/attach/upload`, params);
}

// 销售合同-列表
export function getDtContractList(params = {}) {
  return axios.get(`${BASE_URL}/api/bs/dt/contract/list`, {params});
}

// 销售合同-详情
export function getDtContractInfo(params = {}) {
  return axios.get(`${BASE_URL}/api/bs/dt/contract/info`, {params});
}

// 销售合同-创建合同订单
export function postDsContractCreate(params = {}) {
  return axios.post(`${BASE_URL}/api/bs/dt/contract/create`, params);
}

// 销售合同-保存合同订单
export function postDsContractSave(params = {}) {
  return axios.post(`${BASE_URL}/api/bs/dt/contract/save`, params);
}

// 提前还款-基本信息
export function postRepaymentBaseInfo(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/repayment/base/info`, params);
}

// 提前还款-信息
export function getRepaymentInfo(params = {}) {
  return axios.get(`${BASE_URL}/api/wo/repayment/info`, {params});
}

// 提前还款-基本信息
export function postRepaymentCompute(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/repayment/compute`, params);
}

// 提前还款-发送
export function postRepaymentSend(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/repayment/dispatch/finance`, params);
}

// 提前还款-作废
export function postRepaymentVoid(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/repayment/void`, params);
}

// 提前还款-保存
export function postRepaymentSave(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/repayment/save`, params);
}

// 提前还款-水单上传
export function postRepaymentVoucherSave(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/repayment/payment/voucher/save`, params);
}

// 提前还款-列表
export function getRepaymentList(params = {}) {
  return axios.get(`${BASE_URL}/api/wo/repayment/list`, {params});
}

// 提前还款-导出
export function postRepaymentDownload(params = {}) {
  return axios.get(`${BASE_URL}/api/wo/repayment/batch/download`, {params});
}

// 工单-收付首款-基本信息
export function postCherryFirstBaseInfo(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/cherry/first/payment/base/info`, params);
}

// 工单-收付首款-保存信息
export function postCherryFirstSave(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/cherry/first/payment/save`, params);
}
// 工单-收付首款-作废
export function postCherryFirstVoid(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/cherry/first/payment/void`, params);
}
// 工单-收付首款-发送
export function postCherryFirstSend(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/cherry/first/payment/send`, params);
}
// 工单-收付首款-上传附件
export function postCherryFirstBillSave(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/cherry/first/payment/reveive/bill/save`, params);
}

// 工单-收付首款-详细信息
export function getCherryFirstInfo(params = {}) {
  return axios.get(`${BASE_URL}/api/wo/cherry/first/payment/info`, {params});
}

// 工单-车厘子-上传附件
export function postCommonAttach(params = {}) {
  return axios.post(`${BASE_URL}/api/common/attach`, params);
}

// 工单-出库-作废
export function postOutVoid(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/out/void`, params);
}
