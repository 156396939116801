import axios from 'axios';
import store from '@/store'
import { Message } from 'element-ui';
import common from '@/public/index.js'//公共函数方法引入
import router from "@/router";
// 连接超时时间
axios.defaults.timeout = 10 * 60 * 1000;
// 创建 axios 实例
const ajax = axios.create({ timeout: 10 * 1000 * 60 });
// 设置 Post 请求头
ajax.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
//请求拦截
ajax.interceptors.request.use((req) => {
  const headers = req.headers;
  const token = sessionStorage.getItem("token") ? sessionStorage.getItem("token") : '';
  if (!headers.Authorization) headers.Authorization = token;
  return req;
})

ajax.interceptors.response.use(response => {
  if (response) {
    //响应处理

    if (response.data.code == 200) {
      return response.data.data;
    } else if (response.data.code == 30301 || response.data.code == 30302) {
      Message.error({ message: response.data.msg, showClose: true })
      common.cleanUp();
      store.state.socket.close();
      store.commit("closeSocket");
      setTimeout(() => {
        router.push('/login')
      }, 1000);
    } else {
      Message.error({ message: response.data.msg || '请求失败', showClose: true })
      return Promise.reject(response.data);
    }

  } else {
    // Message.error(response.data.message || '服务端失败')
    return Promise.reject(response.data);
  }

}, err => {
  console.log(err)
  Message.error({ message: err.response?.data?.message || '服务端失败', showClose: true })
  console.log(err.response.data, '错误')
  if (err.response.data.status_code == 500) {
    return Promise.reject(err.response.data);
  } else {
    if (err.response.data.code == 401108) {
      sessionStorage.setItem("token", '')
      sessionStorage.setItem("unread_num", '');
      store.commit("updateToken", "");
      store.commit("updateUnread", "");
      store.state.socket.close();
      store.commit("closeSocket");
      location.reload()
    }
  }


});

export default ajax;
