import { BASE } from './index';
import axios from './http';

const risk = {
    //风控列表数据
    riskpilist: params => axios.get(`${BASE.PRO}/api/risk/pi/list`,  {params} ),
    //预警列表数据
    riskwaringlist: params => axios.get(`${BASE.PRO}/api/risk/price/waring/list`,  {params} ),
    //预警处理
    riskwaringhandle: params => axios.post(`${BASE.PRO}/api/risk/price/waring/handle`,  params ),
    //无价预警-详情
    priceWaringInfo: params => axios.post(`${BASE.PRO}/api/risk/price/waring/info`,  params ),
    //无价预警-确定
    priceWaringDeal: params => axios.post(`${BASE.PRO}/api/risk/price/waring/deal`,  params ),
    //无价预警-日志
    priceWaringLog: params => axios.post(`${BASE.PRO}/api/risk/price/waring/deal/log`,  params ),
    //风控详情
    riskpiinfo: params => axios.get(`${BASE.PRO}/api/risk/info`,  {params} ),
    //风控列解除风控
    riskpirevoke: params => axios.post(`${BASE.PRO}/api/risk/revoke`,  params ),
    //风控列表数据撤销接口
    riskpicancel: params => axios.post(`${BASE.PRO}/api/risk/cancel`,  params ),
    //上传风控附件接口
    riskpiattach: params => axios.post(`${BASE.PRO}/api/risk/attach`,  params ),
    //上传风控驳回
    riskpirefuse: params => axios.post(`${BASE.PRO}/api/risk/refuse`,  params ),
    //上传风控通过
    riskpisure: params => axios.post(`${BASE.PRO}/api/risk/sure`,  params ),
    //批量驳回
    riskbatchrefuse: params => axios.post(`${BASE.PRO}/api/risk/batch/refuse`,  params ),
    //风控列表ci
    cilist: params => axios.get(`${BASE.PRO}/api/risk/ci/list`,  {params} ),
  
   
};

export default risk;
