/**
 * api 接口统一输出
 */
let PRO = process.env["VUE_APP_API_URL"];
// 接口请求地址
export const BASE = { PRO: PRO };


//动态导出api
const files = require.context('.', false, /\.js$/)
const api = {}
files.keys().forEach(key => {
  if (key === './index.js') return
  api[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
})

export default api