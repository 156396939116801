<template>
  <div class="searchQuery">
    <div class="searchtop">
      <el-button
        type="primary"
        icon="el-icon-refresh"
        class="bjsearch"
        @click="Refresh"
      />
      <el-button icon="el-icon-s-fold" plain @click="hideshow = !hideshow">{{
        hideshow ? "隐藏" : "展开"
      }}</el-button>
      <el-button
        :icon="item.icon"
        plain
        v-for="(item, index) in btnlist"
        :key="index"
        @click="events(item)"
        >{{ item.name }}</el-button
      >
    </div>
    <div class="searchbottom" v-show="hideshow">
      <div
        class="inputs marginr8"
        v-for="(item, index) in formList"
        :key="index"
      >
        <el-input
          v-model="searchForm[item.field]"
          :placeholder="item.placeholder"
          clearable
          v-if="item.type == 'input'"
        />
        <el-date-picker
          v-model="searchForm[item.field]"
          type="daterange"
          v-if="item.type == 'datepicker'"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          range-separator="至"
          :start-placeholder="`${item.placeholder}开始日期`"
          :end-placeholder="`${item.placeholder}结束日期`"
        >
        </el-date-picker>
        <el-select
          v-if="item.type == 'select'"
          v-model="searchForm[item.field]"
          clearable
          :placeholder="item.placeholder"
        >
          <el-option
            v-for="e in selectedname[item.field]
              ? selectedname[item.field]
              : []"
            :key="e.id"
            :label="e.label"
            :value="e.id"
          />
        </el-select>
      </div>

      <div class="marginr8">
        <el-button type="primary" icon="el-icon-search" @click="search">
          查询
        </el-button>
        <el-button type="warning" icon="el-icon-refresh" @click="empty">
          重置
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    // 配置筛选项
    quality: {
      type: [Object, Array],
      default: function() {
        //这里默认return一个数组，即可解决上述的报错问题
        return {
          formList: [],
          btnlist: [],
          css: {}
        };
      }
    },
    //下拉框数据值

    selected: {
      type: Object,
      default: function() {
        //这里默认return一个数组，即可解决上述的报错问题
        return {};
      }
    }
  },

  data() {
    return {
      //展开收起
      hideshow: false,
      //查询条件
      searchForm: {},
      //查询条件
      btnlist: [],
      formList: [],
      selectedname: {}
    };
  },
  computed: {},
  watch: {
    quality: {
      handler: function(newValue, oldValue) {
        console.log("quality参数", newValue);
        this.formList = newValue.formList ? newValue.formList : [];
        this.btnlist = newValue.btnlist ? newValue.btnlist : [];
      },
      deep: true
    },
    selected: {
      handler: function(newValue, oldValue) {
        console.log(newValue, "sadsadasdasd1");
        this.selectedname = newValue;
      },
      deep: true
    }
  },
  created() {
    if (!Array.isArray(this.quality)) {
      this.formList = this.quality.formList;
    } else {
      this.formList = [];
    }
    this.btnlist = this.quality.btnlist ? this.quality.btnlist : [];
    this.selectedname = this.selected;
    // console.log(this.selectedname,'sadsadasdasd1')
    // this.btnlist = [{ type: "edit", name: "编辑", icon: "" }];
  },
  mounted() {},
  methods: {
    //点击按钮触发事件
    events(e) {
      this.$emit(e.type, true);
    },

    //点击查询
    search() {
      console.log(this.searchForm);
      this.$emit("search", this.searchForm);
    },
    //清空筛选条件
    empty() {
      for (var key in this.searchForm) {
        if (Array.isArray(this.searchForm[key])) {
          // 如果属性为数组
          this.searchForm[key] = []; // 直接置空数组
        } else if (
          typeof this.searchForm[key] === "object" &&
          !Array.isArray(this.searchForm[key]) &&
          this.searchForm[key] !== null
        ) {
          // 如果属性为非空对象（且非数组）
          // clearObject(this.searchForm[key]); // 递归调用函数自身，清空该对象内的所有属性
          this.searchForm[key] = {};
        } else {
          this.searchForm[key] = "";
        }
      }
    },
    //刷新按钮
    Refresh() {
      this.empty();
      this.$emit("search", this.searchForm);
    }
  }
};
</script>

<style lang="scss">
.searchQuery {
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  .bjsearch {
    background: #333333 !important;
    border-color: #333333 !important;
  }
  .searchtop {
  }
  .searchbottom {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    .inputs {
      width: 207px;
    }
    .marginr8 {
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
}
</style>
