import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import searchQuery from '@/components/renovation/searchQuery.vue'
import searchQuerytable from "@/components/searchQuery/index.vue";
import financeTable from '@/components/renovation/table.vue'
import financePagination from '@/components/renovation/Pagination.vue'
import api from '@/api/index';
import q from '@/api/http.js';
import { BASE } from '@/api/index';
import common from '@/public/index.js'//公共函数方法引入
import '@/utils/tools/dialog.js' //引入全局拖拽

import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/style/custom/index.scss'
import "lib-flexible-computer";
import "default-passive-events";
import VueClipboard from 'vue-clipboard2'
// 引入Echarts
import * as echarts from 'echarts'
Vue.prototype.$api = api; // 将 api 挂在到 vue 原型上
Vue.config.productionTip = false
Vue.prototype.$common = common;
Vue.prototype.$echarts = echarts
Vue.prototype.$q = q//请求挂在到全局
Vue.prototype.$BASE = BASE//请求域名挂在到全局

Vue.use(VueClipboard)
Vue.component('searchQuery', searchQuery)
Vue.component('financeTable', financeTable)
Vue.component('financePagination', financePagination)
Vue.component('searchQuerytable', searchQuerytable)
Vue.use(ElementUI)
// Vue.use(preventClick)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
