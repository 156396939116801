import { BASE } from './index';
import axios from './http';

const renovation = {
    //获取装修表格可配置字段
    decorationsupport: params => axios.get(`${BASE.PRO}/api/bms/decoration/support`,  {params} ),
    //装修保存接口
    decorationsave: params => axios.post(`${BASE.PRO}/api/bms/decoration/save`,  params )
};

export default renovation;
