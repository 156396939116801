import { BASE } from './index';
import axios from './http';

const publicjs = {
    //银行列表页面
    banklist: params => axios.get(`${BASE.PRO}/api/bms/enterprise/bank/group/list`, { params }),
    //银行分组获取账号
    bankgrouplist: params => axios.get(`${BASE.PRO}/api/bms/enterprise/bank/account/group/list`, { params }),
    //列表页面接口
    menulist: params => axios.get(`${BASE.PRO}/api/bms/manager/menu/list`, { params }),
    //业务经理
    menulistselect: params => axios.get(`${BASE.PRO}/api/bms/manager/admin/select`, { params }),
    //客户查询框架合同
    protocolselectlist: params => axios.get(`${BASE.PRO}/api/crm/buyer/directional/protocol/select/list`, { params }),
    //商品列表
    goodstaglist: params => axios.get(`${BASE.PRO}/api/bms/goods/tag/list`, { params }),
    //商品标准列表
    goodslist: params => axios.get(`${BASE.PRO}/api/bms/goods/list`, { params }),
    //商品标准列表新增标签
    goodsaddtag: params => axios.post(`${BASE.PRO}/api/bms/goods/add/tag`,params ),
    //清关公司列表
    clearancelist: params => axios.get(`${BASE.PRO}/api/bms/clearance/list`, { params }),
    //获取融资银行列表
    bankselectlist: params => axios.get(`${BASE.PRO}/api/bms/enterprise/bank/select/list`, { params }),
    //获取银行支行列表
    branchselectlist: params => axios.get(`${BASE.PRO}/api/bms/enterprise/bank/branch/select/list`, { params }),
    //客商下拉栏
    buyerselectlist: params => axios.get(`${BASE.PRO}/api/crm/buyer/select/list`, { params }),
    //获取基础配置
    buyersettingslist: params => axios.get(`${BASE.PRO}/api/crm/buyer/settings/list`, { params }),
    //获取应用模板下拉框
    agreementtemplatelist: params => axios.get(`${BASE.PRO}/api/crm/buyer/agreement/template/list`, { params }),
    //获取基本信息客商
    enterpriseandrate: params => axios.get(`${BASE.PRO}/api/bms/enterprise/title/and/rate`, { params }),
    //获取业务类型
    businessmodeyselect: params => axios.get(`${BASE.PRO}/api/bms/business/mode/select`, { params }),
    //获取币种
    getcurrency: params => axios.get(`${BASE.PRO}/api/bs/order/get/currency`, { params }),
    //获取车厘子品种类型
    getcateType: params => axios.get(`${BASE.PRO}/api/bs/order/cherry/get/category/type`, { params }),
    //获取车厘子品种类型
    getcatetransport: params => axios.get(`${BASE.PRO}/api/bs/order/cherry/get/transport/type`, { params }),
    //获取支付方式
    paymentMethod: params => axios.get(`${BASE.PRO}/api/bs/order/get/paymentMethod`, { params }),
    //工单下拉框
    templateselectlist: params => axios.get(`${BASE.PRO}/api/wo/template/select/list`, { params }),
    //清关-放单方式接口
    clearancegetType: params => axios.get(`${BASE.PRO}/api/bs/order/clearance/get/type`, { params }),
    //清关-完税方式
    clearancegetaxway: params => axios.get(`${BASE.PRO}/api/bs/order/clearance/get/tax/way`, { params }),
    //获取银行总授信额度和剩余额度
    bankresidualcreditget: params => axios.post(`${BASE.PRO}/api/bms/enterprise/bank/residual/credit/get`, params),
    //获取业务品类
    businesscategoryselect: params => axios.post(`${BASE.PRO}/api/bms/business/category/select`, params),
    //获取业务模式
    businesspatternselect: params => axios.post(`${BASE.PRO}/api/bms/business/pattern/select`, params),
    //添加审批数据模拟
    entryinit: params => axios.post(`${BASE.PRO}/api/oa/entry/init`, params),
    //获取内贸客户列表结算
    buyerlist: params => axios.post(`${BASE.PRO}/api/wo/dt/out/buyer/list`, params),
    //客户下仓库列表
    storeroomlist: params => axios.post(`${BASE.PRO}/api/wo/dt/out/storeroom/list`, params),
    //全局的左侧分类列表接口
    businessmodeget: params => axios.get(`${BASE.PRO}/api/bms/business/mode/get`, {params}),
    //PI接单统计
    orderacceptance: params => axios.get(`${BASE.PRO}/api/stat/pi/order/acceptance`, {params}),
};

export default publicjs;
