<template>
  <div class="Paginationview">
    <el-pagination
      type="primary"
      background
      :current-page="currentPagecnt"
      :page-sizes="[10, 20, 50, 100,200,500,1000]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalcnt"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
export default {
  name: "Paginationview",
  components: {},
  props: {
    total: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      currentPagecnt: 1,
      totalcnt: 0,
      paging:{
        page: 1,
        pageSize: 20,
      }
    };
  },
  computed: {},
  watch: {
    total: {
      handler: function (newValue, oldValue) {
        this.totalcnt = newValue;
      },
      deep: true,
    },
    currentPage: {
      handler: function (newValue, oldValue) {
        this.currentPagecnt = newValue;
      },
      deep: true,
    },
  },
  created() {
    this.currentPagecnt = this.currentPage;
    this.totalcnt = this.total;
  },
  mounted() {},
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.paging.pageSize=val
      this.$emit('pagination',this.paging)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.paging.page=val
      this.$emit('pagination',this.paging)
    },
  },
};
</script>

<style  lang="scss">
.Paginationview {
  width: 100%;
  text-align: right;
  background-color: #fff;
  padding: 15px 0px;
  box-sizing: border-box;
}
</style>
